import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
// import * as serviceWorker from './serviceWorker';

// Styles
import './assets/css/Pescadores/home_boots.css';
import './assets/css/Pescadores/reseter.css';
import './assets/css/Pescadores/base.css';
import './assets/css/Pescadores/responsive.css';

// Google Tag Manager
import TagManager from 'react-gtm-module';
const gtm = process.env.REACT_APP_GTM;

const Index = () => {
  // Initialize Google Tag Manager
  useEffect(() => {
    const tagManagerArgs = { gtmId: gtm, dataLayerName: 'PageDataLayer' };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};


ReactDOM.render(<Index />, document.getElementById('root'));

// serviceWorker.register();

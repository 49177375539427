import React from 'react';

// import './ResumenSeguro.css';

const footer = props => {
  const styleFooter = {
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: '600',
  };
  return (
    <div id="footer">
      <div className="content">
        <div className="grid">
          <div className="col-12">
            <div className="right-foo clearfix">
              <div className="legal">
                <p>BancoEstado Corredores de Seguros S.A. es filial de BancoEstado, el cual posee el 50,1% de la
                  propiedad de dicha entidad. El restante 49,9% corresponde a MetLife Chile Inversiones
                  Limitada.</p>
                <p>Seguro intermediado por BancoEstado Corredores de Seguros S.A. La compañía que asume los riesgos es MetLife Chile Seguros de Vida S.A., condiciones depositadas en la CMF bajo los códigos POL 3 2014 0335, POL 3 2013 0163 y POL 3 2021 0141, se consideran parte integrante del presente contrato.</p>
                <p style={{ textAlign: 'center' }}>V{process.env.REACT_APP_VERSION}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default footer;

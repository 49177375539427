import React, { Component } from 'react';

import ResumenSeguro from './ResumenSeguro';
import { Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import DetalleSeguro from './DetalleSeguro_p';
import MetlifeService from '../../services/MetlifeDataService';
import loading from '../../assets/images/Pescadores/loading-orange.gif';
import { formatDate } from '../tools/Tools';
import ReCAPTCHA from 'react-google-recaptcha';
import Declaracion from './Declaracion';
import NoElegible from './NoElegible';

const recaptchaRef = React.createRef();

class Plan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCustomer: null,
      fields: [],
      token: null,
      isFetching: false,
      error: false,
      peso_calc: 0,
      planes: [],
      //Productos: {},
      Productos: {
        1: {
          name: 'seguro_pescador',
          precio: 0,
          uf: 0.25,
          fecha_calc: '11/05/2020',
          cobertura_uf: 180,
          cobertura_peso: 5168974,
          style_seg1: 'individual',
          style_seg2: 'precio_al_ano',
        },
      },
      productoEscogido: 1,
      newCustomer: {
        customer: {},
        product: {},
        payment: {
          amount: 0,
          method: 'WEBPAY',
        },
        peso_calc: 0,
        fecha_calc: '',
        urlOrigin: window.location.href,
      },
      showDeclaracion: false,
      showNoElegible: false,
      declaracionOk: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  productChangedHandler = id => {
    const product = this.productById(id);

    this.setState({
      productoEscogido: id,
      newCustomer: {
        ...this.state.newCustomer,
        product,
        payment: {
          amount: this.state.Productos[id].precio,
          method: 'WEBPAY',
        }
      }
    });
    
    if (product.planCode == "app3" || product.planCode == "app4") {
      this.setState({ showDeclaracion: true });
    }

    return product;
  };

  componentDidMount() {
    this.setState({ isFetching: true });
    MetlifeService.getUFPlanesCoverage('app')
      .then(res => {
        this.setState({ peso_calc: res[0].data.value });
        let planes = res[1].data;
        planes?.map(plan => {
          plan.coverages = res[2].data?.find(p => p.planCode == plan.planCode);
        });
        this.setState({ planes });

        this.state.planes.map(plan => {
          //if (plan.product_code == 'app'){
          this.state.Productos[plan.id] = {};
          this.state.Productos[plan.id].name = plan.name;
          this.state.Productos[plan.id].uf = plan.price;
          this.state.Productos[plan.id].cobertura_uf = plan.coverage;
          this.state.Productos[plan.id].precio = this.state.peso_calc * plan.price;
          this.state.Productos[plan.id].cobertura_peso =
            this.state.peso_calc * plan.coverage;
          this.state.Productos[plan.id].id = plan.id;
          this.state.Productos[plan.id].precio = this.state.Productos[plan.id].precio.toFixed(
            0
          );

          // fecha
          let today = new Date();
          let day = today.getDate();
          let month = today.getMonth() + 1;
          this.state.Productos[plan.id].fecha_calc = formatDate(res[0].data.id.publicationDate);
          //}
          //this.state.newCustomer.customer = {};
          this.state.newCustomer.product = this.productById(
            this.state.productoEscogido
          );

          this.state.newCustomer.payment = {
            amount: this.state.Productos[this.state.productoEscogido].precio,
            method: 'WEBPAY',
          };
          this.state.newCustomer.peso_calc = this.state.peso_calc;
          this.state.newCustomer.fechaCalc = this.state.Productos[plan.id].fecha_calc;
        });

        this.setState({ isFetching: false });
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: true });
      });
  }

  productById(id) {
    let planEscogido = null;
    this.state.planes.map(plan => {
      if (plan.id == id) {
        planEscogido = plan;
        planEscogido.code = 'app';
      }
    });
    return planEscogido;
  }

  async handleValidation() {
    let errors = {};
    let formIsValid = true;
    
    if(this.state.newCustomer.product.planCode == "app3" || this.state.newCustomer.product.planCode == "app4") {
      if(!this.state.declaracionOk) formIsValid = false;
    }

    this.setState({ errors: errors });
    if (Object.keys(errors).length > 0) formIsValid = false;
    return formIsValid;
  }

  async handleFormSubmit(e) {
    // Form submission logic
    if(e) e.preventDefault();
    if (await this.handleValidation()) {

      const token = await recaptchaRef.current.executeAsync();
      if (token) {
        this.state.fields["recaptcha"] = token;
      }

      //
      MetlifeService.getToken({
        site: recaptchaRef.current.getValue(),
      })
        .then(res => {
          this.setState({
            token: res.headers.authorization,
          });
          
          const newCustomer = {
           ...this.state.newCustomer,
           customer: {
              firstName: '',
              lastName: '',
              rut: this.state.fields['rut'],
              numberrut: this.state.fields['numberrut'],
              rutformat: this.state.fields['rut'],
              dvRut: '0',
              birthdate: '',
              email: '',
              phoneNumber: '',
              commune: '',
              address: '',
              recaptcha: this.state.fields['recaptcha'],
            },
            token: res.headers.authorization,
          };

          this.props.history.push({
            pathname: '/insured',
            state: { newCustomer },
          });

        })
        .catch(e => {
          console.log(e);
          this.setState({ error: true });
        });
    }
  }

  render() {
    let mejora = null;

    if (this.state.isFetching) {
      if (this.state.error) {
        return (
          <div className="btn-continuar">
            Momentáneamente nuestra página no está disponible, inténtalo más
            tarde. Gracias por tu comprensión
          </div>
        );
      } else {
        return (
          <div className="btn-continuar">
            <img className="center" src={loading} />
          </div>
        );
      }
    } else {
      return (
        <div id="contenedor" style={{ width: '80%' }}>
          <div id="simulador">
            <Form
              id="formInstance"
              name="formInstance"
              className="form form-general form-home"
              onSubmit={this.handleFormSubmit}>
              <Declaracion
                show={this.state.showDeclaracion}
                onFailure={() => this.setState({ showNoElegible: true, declaracionOk: false})}
                onSuccess={()=> this.setState({ declaracionOk: true })}
                onClose={() => this.setState({ showDeclaracion: false })}
                onExit={() => this.setState({ showDeclaracion: false, declaracionOk: false})}
                />
              <NoElegible
                show={this.state.showNoElegible}
                onClose={() => this.setState({ showNoElegible: false})}/>
              <ResumenSeguro
                uf={this.state.Productos[1].uf}
                precio={this.state.Productos[1].precio}
                fechaCalc={this.state.Productos[1].fecha_calc}
                pesoCalc={this.state.peso_calc}
              />
              <DetalleSeguro pesoCalc={this.state.peso_calc} planes={this.state.planes} changePlan={this.productChangedHandler} />

              <ReCAPTCHA
                ref={recaptchaRef}
                style={{ zIndex: 100, position: 'absolute' }}
                size='invisible'
                sitekey="6Lc70R0kAAAAAAgnxn9be2rvy1xmU_tGVtoMYV63"
              />
            </Form>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(Plan);

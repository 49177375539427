import React from 'react';
import Table from 'react-bootstrap/Table';
import NumberFormat from 'react-number-format';
import moment from 'moment'

export const dateNow = moment().format('DD/MM/YYYY');

// import '../../assets/css/Home/ResumenSeguro.css';

const detalleseguro_p = props => {
  const style1 = {
    textAlign: 'justify',
    paddingTop: '50px',
    paddingBottom: '50px',
    overflow: 'auto'
  };

  const styleCenter = {
    textAlign: 'center',
    verticalAlign: 'middle'
  }

  const styleBold = {
    fontWeight: 'bold',
    fontFamily: 'sans-serif'
  }

  props.planes.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

  return (
    <div className="modulos-grales wbullet top-yellow" style={style1}>
      <Table striped bordered hover responsive className="coverage" style={{ border: 0 }}>
        <thead>
          <tr style={{ backgroundColor: "#fa9600", color: 'white' }}>
            <th>Cobertura</th>
            {props.planes?.map(p => (
              <th style={styleCenter} key={"plan_" + p.id}>{p.name}{(p.planCode == "app3" || p.planCode == "app4") && <p>Requiere DPS</p>}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Si falleces por consecuencia de <b>Muerte Accidental</b> tendrás un capital de herencia</td>
            {props.planes?.map((p) => (
              <td key={"ma_" + p.id} style={styleCenter}>{p?.coverages.acc_death ? (
                <div>
                  <NumberFormat
                    value={p?.coverages.acc_death}
                    displayType={'text'}
                    prefix={' UF '}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  /><br />(
                  <NumberFormat
                    value={p?.coverages.acc_death * props.pesoCalc}
                    displayType={'text'}
                    prefix={'$ '}
                    decimalScale={0}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />)*
                </div>
              ) : "X"}
              </td>
            ))}
          </tr>
          <tr>
            <td>Si sufres de <b>Incapacidad Total y Permanente 2/3 por Accidente</b> tendrás</td>
            {props.planes?.map((p) => (
              <td key={"ma_" + p.id} style={styleCenter}>{p?.coverages.acc_inhability ? (
                <div>
                  <NumberFormat
                    value={p?.coverages.acc_inhability}
                    displayType={'text'}
                    prefix={' UF '}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  /><br />(
                  <NumberFormat
                    value={p?.coverages.acc_inhability * props.pesoCalc}
                    displayType={'text'}
                    prefix={'$ '}
                    decimalScale={0}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />)*
                </div>
              ) : "X"}
              </td>
            ))}
          </tr>
          <tr>
            <td>Si sufres un <b>Desmembramiento por accidente</b> contarás con un capital de</td>
            {props.planes?.map((p) => (
              <td key={"ma_" + p.id} style={styleCenter}>{p?.coverages.acc_dismemberment ? (
                <div>
                  <NumberFormat
                    value={p?.coverages.acc_dismemberment}
                    displayType={'text'}
                    prefix={' UF '}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  /><br />(
                  <NumberFormat
                    value={p?.coverages.acc_dismemberment * props.pesoCalc}
                    displayType={'text'}
                    prefix={'$ '}
                    decimalScale={0}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />)*
                </div>
              ) : "X"}
              </td>
            ))}
          </tr>
          <tr>
            <td>Si sufres de una <b>Fractura de Huesos</b> por accidente tendrás</td>
            {props.planes?.map((p) => (
              <td key={"ma_" + p.id} style={styleCenter}>{p?.coverages.acc_bone_fracture ? (
                <div>
                  <NumberFormat
                    value={p?.coverages.acc_bone_fracture}
                    displayType={'text'}
                    prefix={' UF '}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  /><br />(
                  <NumberFormat
                    value={p?.coverages.acc_bone_fracture * props.pesoCalc}
                    displayType={'text'}
                    prefix={'$ '}
                    decimalScale={0}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />)*
                </div>
              ) : "X"}
              </td>
            ))}
          </tr>
          <tr>
            <td>Por <b>Fallecimiento</b> dejarás un capital de herencia de</td>
            {props.planes?.map((p) => (
              <td key={"ma_" + p.id} style={styleCenter}>{p?.coverages.death_inheritance ? (
                <div>
                  <NumberFormat
                    value={p?.coverages.death_inheritance}
                    displayType={'text'}
                    prefix={' UF '}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  /><br />(
                  <NumberFormat
                    value={p?.coverages.death_inheritance * props.pesoCalc}
                    displayType={'text'}
                    prefix={'$ '}
                    decimalScale={0}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />)*
                </div>
              ) : "X"}
              </td>
            ))}
          </tr>
          <tr>
            <td>
              <p>Si eres diagnosticado por estas 8 <b>Enfermedades Graves</b> contarás con un capital de indenmización de</p>
              <p>(Cáncer, ACV, Enfermedades Cardiacas, Angioplastía por Balón, injerto aórtico, Cirugías de válvulas cardiacas, Revascularización coronaria)</p>
            </td>
            {props.planes?.map((p) => (
              <td key={"ma_" + p.id} style={styleCenter}>{p?.coverages.serious_diseases ? (
                <div>
                  <NumberFormat
                    value={p?.coverages.serious_diseases}
                    displayType={'text'}
                    prefix={' UF '}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  /><br />(
                  <NumberFormat
                    value={p?.coverages.serious_diseases * props.pesoCalc}
                    displayType={'text'}
                    prefix={'$ '}
                    decimalScale={0}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />)*
                </div>
              ) : "X"}
              </td>
            ))}
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td style={styleBold}>Valor Prima Total</td>
            {props.planes?.map(p => (
              <td style={{ ...styleBold, ...styleCenter }} key={"price_plan_" + p.id}>
                <NumberFormat
                  value={p.price}
                  displayType={'text'}
                  prefix={' UF '}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                /><br />(
                <NumberFormat
                  value={p.price * props.pesoCalc}
                  displayType={'text'}
                  prefix={'$ '}
                  decimalScale={0}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                />)*
              </td>
            ))}
          </tr>
          <tr style={{ border: 0 }}>
            <td style={{ border: 0 }}></td>
            {props.planes?.map(p => (
              <td key={"contratar_" + p.id} style={{ border: 0 }}>
                <button
                  className="accion contratacion btn_enviar behavior"
                  onClick={x => { props.changePlan(p.id); }}
                  type="submit">
                  Contrata
                  Aquí
                </button>
              </td>
            ))}
          </tr>
        </tfoot>
      </Table>
      <br/><br/>
      <span style={{ fontSize: '13px' }}>
        (*) Valor UF  referencial de
        <NumberFormat
            value={props.pesoCalc}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={' $ '}
            decimalScale={0}
        /> del día {dateNow}
    </span>
    </div>
  );
};

export default detalleseguro_p;
